import React, { useState, useRef } from 'react';
import { Menu, X, ChevronLeft, ChevronRight, ChevronDown, ChevronUp } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const NavItem = ({ text, href }) => (
  <a href={href} className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
    {text}
  </a>
);

const MobileNavItem = ({ text, href, toggleMenu }) => (
  <a
    href={href}
    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
    onClick={toggleMenu}
  >
    {text}
  </a>
);

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img className="h-8 w-8" src="/api/placeholder/32/32" alt="Logo" />
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <NavItem text="Home" href="#home" />
                <NavItem text="Services" href="#services" />
                <NavItem text="About" href="#about" />
                <NavItem text="Contact" href="#contact" />
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? <X className="block h-6 w-6" /> : <Menu className="block h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="md:hidden"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <MobileNavItem text="Home" href="#home" toggleMenu={toggleMenu} />
              <MobileNavItem text="Services" href="#services" toggleMenu={toggleMenu} />
              <MobileNavItem text="About" href="#about" toggleMenu={toggleMenu} />
              <MobileNavItem text="Contact" href="#contact" toggleMenu={toggleMenu} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

const Hero = () => (
  <div className="relative bg-gray-50 overflow-hidden">
    <div className="max-w-7xl mx-auto">
      <div className="relative z-10 pb-8 bg-gray-50 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Innovative Mobile</span>{' '}
              <span className="block text-indigo-600 xl:inline">Software Solutions</span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Transforming ideas into powerful mobile applications. We build cutting-edge software tailored to your business needs.
            </p>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="#contact"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                >
                  Get started
                </a>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <a
                  href="#services"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                >
                  Our services
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="app-construction.webp" alt="Mobile development" />
    </div>
  </div>
);

const Services = () => {
  const [expandedService, setExpandedService] = useState(null);

  const services = [
    {
      title: "Native Mobile Application Development",
      description: "We create high-performance native apps that engage users and scale as you grow. From iOS to Android, our solutions are tailored to meet your unique business goals.",
      icon: "/api/placeholder/24/24",
    },
    {
      title: "Technology Conversion Services",
      description: "Moving from cross-platform to native? We seamlessly convert your apps from frameworks like Flutter to native, ensuring better performance, enhanced user experience, and compatibility with platform-specific features.",
      icon: "/api/placeholder/24/24",
    },
    {
      title: "SDK Development",
      description: "We build robust, easy-to-integrate SDKs that allow your team or clients to expand functionality quickly and efficiently. Our SDKs are designed for seamless integration, enabling developers to build confidently on top of a solid foundation.",
      icon: "/api/placeholder/24/24",
    },
    {
      title: "UX Design & Strategy",
      description: "Great apps are built on great user experiences. We design intuitive, beautiful, and effective UX tailored to your users’ needs and behaviors. From wireframes to polished designs, we ensure your app looks and feels exceptional.",
      icon: "/api/placeholder/24/24",
    },
    {
      title: "Consulting & Strategy",
      description: "Unclear on the best path forward? Our consulting services guide you with in-depth insights and strategic direction, helping you make decisions that align with your goals and resources.",
      icon: "/api/placeholder/24/24",
    },
    {
      title: "Team Leadership",
      description: "Need someone to lead your team? We provide experienced tech leads who can steer projects with strong direction and seamless coordination, helping your team reach new levels of productivity and quality.",
      icon: "/api/placeholder/24/24",
    },
    {
      title: "Talent Acquisition Assistance",
      description: "Building your own team? We help you find the right talent to match your technical needs and culture, ensuring long-term success for your projects and company.",
      icon: "/api/placeholder/24/24",
    },
  ];

  // Toggle service expansion (allow multiple items to be expanded)
  const toggleService = (index) => {
    setExpandedService(expandedService === index ? null : index); // Collapse or expand the clicked item
  };

  return (
    <section id="services" className="text-gray-900 body-font bg-gray-100 py-16" style={{ minHeight: '600px' }}>
      {/* Title Section */}
      <div className="text-center mb-12">
        <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">HOW WE CAN HELP</h2>
        <h1 className="sm:text-1xl text-2xl font-medium title-font text-gray-900">Our Services</h1>
        <p className="text-sm text-gray-600 mt-4 py-2 max-w-4xl mx-auto">
          We specialize in native mobile applications for startups and enterprises alike.
          Our services cover everything from initial concept and strategic consulting to full-scale application development and deployment.
          What sets us apart is our ability to deliver native and quality applications within a comparable budget and timeframe to cross-platform solutions.
          Our team brings the experience, agility, and strategic thinking needed to make your mobile app vision a reality.
        </p>
      </div>

      {/* Services Content Section */}
      <div className="container px-5 mx-auto max-w-6xl flex items-center justify-between" style={{ height: '100%' }}>
        {/* Left Services Cards Section */}
        <div className="w-1/2 pr-12">
          <div className="bg-white p-6 rounded-lg shadow-md space-y-4">
            {services.map((service, index) => (
              <div
                key={index}
                onClick={() => toggleService(index)}
                className="cursor-pointer p-3 rounded-md transition-all duration-300 hover:bg-gray-200"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img src={service.icon} alt={`${service.title} icon`} className="w-6 h-6 mr-4" />
                    <h2 className="text-lg font-medium text-gray-800">{service.title}</h2>
                  </div>
                  <span className="text-gray-600">{expandedService === index ? '-' : '+'}</span>
                </div>
                {expandedService === index && (
                  <p className="mt-2 text-sm text-gray-600">{service.description}</p>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Right Image Section */}
        <div className="w-1/2 flex justify-center items-center" style={{ height: '100%' }}>
          <img
            src="/path/to/your-image.jpg" // Replace with your image path
            alt="Services Image"
            className="w-full h-auto rounded-lg shadow-md"
          />
        </div>
      </div>
    </section>
  );
};


const ProjectCard = ({ title, description, image }) => (
  <div className="flex-shrink-0 w-full md:w-1/3 p-4">
    <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden flex flex-col md:flex-row">
      <img className="lg:h-48 md:h-36 w-full md:w-1/3 object-cover object-center" src={image} alt={title} />
      <div className="p-6 flex-1">
        <h2 className="text-lg font-medium text-gray-900 mb-3">{title}</h2>
        <p className="leading-relaxed mb-3 text-sm">{description}</p>
        <div className="flex items-center flex-wrap ">
          <a className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0 text-sm">Learn More
            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
);

const RecentProjects = () => {
  const projects = [
    {
      title: "E-commerce Mobile App",
      description: "A full-featured e-commerce app with real-time inventory and secure payments.",
      image: "/api/placeholder/400/300"
    },
    {
      title: "Fitness Tracker",
      description: "An AI-powered fitness app that creates personalized workout plans.",
      image: "/api/placeholder/400/300"
    },
    {
      title: "Social Media Dashboard",
      description: "A unified dashboard for managing multiple social media accounts.",
      image: "/api/placeholder/400/300"
    },
    {
      title: "AR Navigation Game",
      description: "An augmented reality app for indoor navigation in large facilities.",
      image: "/api/placeholder/400/300"
    },
    {
      title: "IoT Home Control",
      description: "A smart home app that integrates with various IoT devices for home automation.",
      image: "/api/placeholder/400/300"
    },
    {
      title: "Language Learning Game",
      description: "An interactive language learning app using gamification techniques.",
      image: "/api/placeholder/400/300"
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);

  const nextSlide = () => {
    if (currentIndex < projects.length - 3) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">OUR WORK</h2>
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">Recent Projects</h1>
        </div>
        <div className="relative">
          <button 
            onClick={prevSlide} 
            className="absolute left-0 z-10 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
            disabled={currentIndex === 0}
          >
            <ChevronLeft className={`w-6 h-6 ${currentIndex === 0 ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
          <button 
            onClick={nextSlide} 
            className="absolute right-0 z-10 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
            disabled={currentIndex >= projects.length - 3}
          >
            <ChevronRight className={`w-6 h-6 ${currentIndex >= projects.length - 3 ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
          <div className="overflow-hidden" ref={carouselRef}>
            <motion.div 
              className="flex"
              initial={false}
              animate={{ x: `-${currentIndex * 33.33}%` }}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
            >
              {projects.map((project, index) => (
                <ProjectCard key={index} {...project} />
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Clients = () => {
  const clients = [
    { name: "TechCorp", logo: "/api/placeholder/150/50" },
    { name: "InnovateSoft", logo: "/api/placeholder/150/50" },
    { name: "MobileMasters", logo: "/api/placeholder/150/50" },
    { name: "AppGenius", logo: "/api/placeholder/150/50" },
    { name: "SmartSolutions", logo: "/api/placeholder/150/50" },
    { name: "FutureTech", logo: "/api/placeholder/150/50" },
  ];

  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">Trusted by Industry Leaders</h2>
        <div className="flex flex-wrap justify-center items-center">
          {clients.map((client, index) => (
            <div key={index} className="m-4">
              <img 
                src={client.logo} 
                alt={`${client.name} logo`} 
                className="h-12 object-contain filter grayscale hover:grayscale-0 transition-all duration-300"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const About = () => (
  <section id="about" className="text-gray-600 body-font">
    <div className="container px-5 py-24 mx-auto flex flex-wrap">
      <div className="flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
        <div className="w-full sm:p-4 px-4 mb-6">
          <h1 className="title-font font-medium text-xl mb-2 text-gray-900">About Us</h1>
          <div className="leading-relaxed">We are a team of passionate developers dedicated to creating innovative mobile applications. With years of experience and a commitment to quality, we deliver solutions that drive your business forward.</div>
        </div>
        <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
          <h2 className="title-font font-medium text-3xl text-gray-900">50+</h2>
          <p className="leading-relaxed">Clients</p>
        </div>
        <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
          <h2 className="title-font font-medium text-3xl text-gray-900">100+</h2>
          <p className="leading-relaxed">Projects</p>
        </div>
        <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
          <h2 className="title-font font-medium text-3xl text-gray-900">5</h2>
          <p className="leading-relaxed">Years</p>
        </div>
      </div>
      <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
        <img className="object-cover object-center w-full h-full" src="/api/placeholder/600/300" alt="Team" />
      </div>
    </div>
  </section>
);

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // If successful, handle response here
      console.log('Form submitted:', formData);
      alert('Thank you for your message. We will get back to you soon!');

      // Reset form data
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error sending email:', error);
      alert('There was an error sending your message. Please try again later.');
    }
  };

  return (
    <section id="contact" className="text-gray-600 body-font relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Contact Us</h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">We'd love to hear from you. Get in touch with us for any inquiries or project discussions.</p>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          <form onSubmit={handleSubmit} className="flex flex-wrap -m-2">
            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="name" className="leading-7 text-sm text-gray-600">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required
                />
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  required
                ></textarea>
              </div>
            </div>
            <div className="p-2 w-full">
              <button type="submit" className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

const Footer = () => (
  <footer className="text-gray-600 body-font">
    <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
      <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
        <img src="/api/placeholder/24/24" alt="Logo" className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" />
        <span className="ml-3 text-xl">MobileSoft Solutions</span>
      </a>
      <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
        © 2024 MobileSoft Solutions —
        <a href="https://twitter.com/mobilesoft" className="text-gray-600 ml-1" rel="noopener noreferrer" target="_blank">@mobilesoft</a>
      </p>
      <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
        <a className="text-gray-500">
          <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </a>
        <a className="ml-3 text-gray-500">
          <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a>
        <a className="ml-3 text-gray-500">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
          </svg>
        </a>
        <a className="ml-3 text-gray-500">
          <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
    </div>
  </footer>
);

// Main App component
const App = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow">
        <Hero />
        <Services />
        <RecentProjects />
        <Clients />
        <About />
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default App;